/* ---=== -- Blue Steel Theme -- CSS -- --==-- */
@import "bsColors";
/* === Footer Styles SCSS == */

/* ---- Footer Region ---#### MOBILE - BASE ---###--- */
.layout-flexible-content footer.flexible-row.l-footer {
	border: none;
    padding: 0;
    margin: 50px 0 0;
    color: #fff;
    overflow-x: hidden;
	background-color: transparent;
	
	footer.flexible-row.l-footer {
		margin: auto;
	}
	
	.container.region_12.footer-region {
		background: linear-gradient(to right, $onyx, $drab, $onyx);
		max-width: 100%;
		padding: 0;
		
		.l-flexible-row.row {
			margin-left: auto;
			margin-right: auto;
			padding: 0 3.2%;
			
			.l-col.col-md-12.footer-container {
				background-color: transparent;
				padding-top: 2px;
				padding-left: 0;
				padding-right: 0;
				
				.block-mini-layouts-hallowell-footer .block-content footer.l-footer {
					.container.footernav {
						.l-flexible-row.row .l-col.col-md-3 {
							h2.block-title {
								color: $steelBluegreen;
								font-weight: 500;
							}
							.block-content ul.menu {
								display: flex;
								align-items: center;
								justify-content: center;
								flex-wrap: wrap;
								
								li.leaf {
									width: 100%;
									float: none;
									list-style: none;
									margin: 0;
									padding: 0;
									
									a,
									a:link, 
									a:visited {
										display: block;
										padding: 0;
										font: {
											size: 18px;
											weight: 500;
										}
									    letter-spacing: 1.4px;
										line-height: 2.1;
										color: $sage;
										width: fit-content;
										transition: .5s ease-out;
										
									}
									a:hover {
										color: $ice2;
										text-decoration: none;
										transition: 300ms ease;
									}
									a:active {
										color: $ice2;
										transition: 50ms;
									}
									a::after {
										@include hovUnderOff;
										height: 1px;
										top: -8px;
									}
									a:hover::after, a:active::after {
										@include hovUnderOn;
									}
								}
							}
						}
					}
					
		/* ####++++ STAAART footer TAG #### MOBILE ++ BASE ---###--------###*/ 
					.container.footertag {
						.block.block-block-footer-logo-and-link.footer-logo-link-wrap {
							text-align: center;
						}
						.copy-right {
							margin-top: 30px;
							
							.hallLogo-footer {
								line-height: 1;
								
								img {
									max-width:100px;
								}
							}
							.hallMadAmerica-footer {
								line-height: 1;
								
								img {
									max-width:175px;
								}
							}
							p.copyright-text {
								font-size: 10px;
								font-weight: 300;
								line-height: 1;
							}
						}
					}
				}
			}
		}
	}		
} /* #### end footer element #### MOBILE - BASE ---###--------###*/ 

/* ##==## MEDIA QUERY #### MaxWidth660 -+++++ ---###---===--###*/ 
@media(max-width:660px) {
	.layout-flexible-content footer.flexible-row.l-footer {
		.container.region_12.footer-region {
			.l-flexible-row.row {
				.l-col.col-md-12.footer-container {
					.block-mini-layouts-hallowell-footer .block-content footer.l-footer {
						.container.footernav .l-flexible-row.row .l-col.col-md-3 {
							h2.block-title {
								text-align: left;
								margin-top: 14px;
							}
							.block-content ul.menu {
								li.leaf {
									padding: 4px 0;
									
									a,
									a:link, 
									a:visited {
										padding: 0 20px;
										font: { size: 18px; }
										background-color: #dee2ea00;
										border: 1px solid #ececec0d;
									}
									a:active {
										color: $ice2;
										background-color: $onyx;
										border-color: $sage;
										transition: 50ms;
									}
								}
							}
						}
						
			/* ####++++ STAAART footer TAG #### MOBILE ++ ONLY --max660-###--------###*/ 
						.container.footertag {
							.block.block-block-footer-logo-and-link {
								text-align: center;
								
								.block-content {
								
									.copy-right {
										margin-top: 60px;
										margin-bottom: 38px;
										
										.hallLogo-footer,
										.hallMadAmerica-footer,
										p.copyright-text {
											line-height: 1;
											text-align: center;
										}
										
										.hallLogo-footer {
											img {
												max-width:157px;
											}
										}
										.hallMadAmerica-footer {
											img {
												max-width: 138px;
												margin-top: 4px;
											}
										}
										p.copyright-text {
											font-size: 10px;
											font-weight: 300;
										}
									}
								}
							}
						}
					}
				}
			}
		}		
	} /* #### end footer MOBILE ONLY max-660 ---###--------###*/ 
} /* ##==## END ### MaxWidth660 -----=--###*/ 

/* ##==## MEDIA QUERY #### min-width:660 -+++++ ---###---===--###*/ 
@media(min-width:660px) {

} /* ##==## END - #### min-width:660 -----=--###*/ 

/* ++++##==## MEDIA QUERY #### 960px -+++++ ---###---===--###*/ 
@media(min-width:960px) {
	
} /* ---##==## END - = MEDIA QUERY #### 960px -----=--###*/ 


