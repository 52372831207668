/* ###
Blue Steel - Colors: 
### */

$sand: #B9B0A2;

$lightIce: #F8F8F8;
$ice: #F0F3F4; /* ## lt gray ## */
$ice2: #E1E7E9;
$iceGray: #dee2ea;
$steelGray: #C8D1D3;
$steelGrayDrk: #707070; 

$sageLte: #d2e4e5;
$sage: #87BCBF;
$sage2: #87BFA1;

$LessRust: #F8B99E;
$rust: #D97D54;
$rustBtn: #EEA888;

$linkBlue: #0073bd;
$linkBlue1: #7AB4DB;
$linkBlue2: #4E86AB;

$steelBlueLte: #AFC7D6;
$steelBluegreenLte: #86A1B4;
$steelBluegreen: #6E8CA0;
$steelBlueDrk: #587384;
$steelBlue: #006585; 

$drab: #324755; /* ## Drab -  Main Dark ## */
$drabber: #003E51; /* ## Drab 2 -  2nd Dark ## */
$drabber2: #283843;
$drabber3: #0a4153;

$lightBlack: #333333;
$onyxLte: #21282D;
$onyx: #1B1C20;

$shadow_A: #c8c8cd;

$bodyFont: europa, OpenSans, 'Open Sans', sans-serif; /* ## body text ## */
$smallFont: 'Open Sans', Arial, sans-serif;
$specialFont: 'Oxygen', 'source sans pro', Arial, sans-serif;
$labelFont: 'source sans pro', 'Source Sans Pro','Open Sans', Arial, sans-serif;
$subFont: Chivo, OpenSans, sans-serif;  /*###Sub-Heading Special##*/
$headingFont: Oswald, sans-serif; /* ## block heading text ## */
$linkFont: 'rift soft', rift-soft, sans-serif;
$chartFont: roboto, 'Open Sans', Arial, sans-serif;
@mixin horozontalLine {
	width: 20%;
	height: 2px;
	display: inline-block;
	content: "";
	background-color: $ice;
}
/*--- mixin for a::after ---*/
@mixin hovUnderOff {
	content:"";
	position: relative;
	width:0;
	height:2px;
	top:-4px;
	background-color:$sage;
	margin:auto;
	display: block;
	transition:.4s ease;								
}
/*--- mixin for a:hover::after , a:active::after ---*/  
@mixin hovUnderOn {
	width:100%;
	transition:.2s ease;
}
@mixin companyDesc {
	font: {
		family: $headingFont;
		weight: 400;
		size: 14px;
	}
	color: $drab;
}
@mixin centerImg {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
@mixin fontReset {
	font: {
		family: 'Open Sans', sans-serif;
		weight: 800;
		style: oblique;
	}
	color: #aecacc;
}
@mixin blkTitlsMF {
	font: {
		family: $headingFont;
		weight: 300;
		size: 24px;
	}
	border-bottom: 1px solid #6E8CA0;
	z-index: 2;
	padding: 15px 15px 5px;
	top: -9px;
	color: $drab;
	text-transform: capitalize;
	margin: 0 28px;
	width: auto;
	right: 0;
	left: 0;
}